import { useEffect, useState } from "react";

export default function ModalForm({ isOpen, onClose }) {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setAnimationClass("modal-enter");
      document.body.style.overflow = "hidden";
    } else {
      setAnimationClass("modal-exit");
      setTimeout(() => setIsVisible(false), 170);
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
      onClick={handleBackgroundClick}
    >
      <div
        className={`relative bg-white rounded-lg p-8 max-w-lg mx-auto shadow-lg w-full ${animationClass}`}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        <h2 className="text-4xl font-semibold text-center mb-4 text-gray-800">
          Contact Us
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Fill out the form below, and one of our specialists will get in touch
          with you shortly.
        </p>
        <form className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Owner Name
            </label>
            <input
              type="text"
              placeholder="Enter your full name"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-pinto focus:ring-2 focus:ring-pinto"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Name
            </label>
            <input
              type="text"
              placeholder="Enter your business name"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-pinto focus:ring-2 focus:ring-pinto"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              placeholder="youremail@example.com"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-pinto focus:ring-2 focus:ring-pinto"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="tel"
              placeholder="(XXX) XXX-XXXX"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-pinto focus:ring-2 focus:ring-pinto"
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 bg-pinto hover:bg-pinto-dark text-white font-semibold rounded-md shadow-lg transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
